import React from "react";
import styled from "styled-components";
//
export default ({ image, title, items, ...rest }) => (
  <Wrap>
    <img src={image.url} alt={title} />
  </Wrap>
);
//
const Wrap = styled.div`
  text-align: center;

  img {
    max-height: 70vh;
    padding-bottom: 2rem;
  }
`;
